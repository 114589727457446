// import { React, useState, useEffect } from "react";
// import { Container, useMediaQuery, styled, useTheme } from "@mui/material";
// import aliAfter from "../assets/Ali.jpg";
// import {
//   Navbar,
//   SideAnchorLinks,
//   Hero,
//   About,
//   Experience,
//   Projects,
//   // Hobbies,
//   Footer,
// } from "../components";

// const StyledMainPage = styled("div")(({ theme }) => ({
//   backgroundColor: theme.palette.background.main,
//   overflow: "hidden",
// }));
// const StyledMainContainer = styled(Container)({
//   maxWidth: "1600px",
// });

// const Main = () => {
//   const theme = useTheme();
//   const showSideAnchor = useMediaQuery(theme.breakpoints.up("lg"));
//   const [isLoading, setIsloading] = useState(true);
//   const [heroImg, setHeroImg] = useState(null);

//   //Loader animation before rest of the page gets rendered
//   useEffect(() => {
//     setTimeout(() => {
//       setIsloading(false);
//     }, 2400);

//     const loadImage = async () => {
//       const img = new Image();
//       img.src = aliAfter;
//       await img.decode();
//       setHeroImg(aliAfter);
//     };
//     loadImage();
//   }, []);

//   //
//   return (
//     <>
//       <Navbar />
//       <StyledMainPage>
//         {showSideAnchor && <SideAnchorLinks />}
//         <StyledMainContainer>
//           <Hero img={heroImg} />
//           <About />
//           <Experience />
//           <Projects />
//           {/* <Hobbies /> */}
//           <Footer />
//         </StyledMainContainer>
//       </StyledMainPage>
//     </>
//   );
// };

// export default Main;
import React, { useState, useEffect, useCallback } from "react";
import { Container, useMediaQuery, styled, useTheme } from "@mui/material";
import aliAfter from "../assets/Ali2.jpg";
import {
  Loader,
  Navbar,
  SideAnchorLinks,
  Hero,
  About,
  Experience,
  Projects,
  Footer,
} from "../components";

const StyledMainPage = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  overflow: "hidden",
}));
const StyledMainContainer = styled(Container)({
  maxWidth: "1600px",
});

const Main = () => {
  const theme = useTheme();
  const showSideAnchor = useMediaQuery(theme.breakpoints.up("lg"));
  const [isLoading, setIsLoading] = useState(true);
  const [heroImg, setHeroImg] = useState(null);

  // Load the image and set it in the state
  const loadImage = useCallback(async () => {
    const img = new Image();
    img.src = aliAfter;
    img.decoding = "async"; // Hint to the browser to load the image asynchronously
    await img.decode();
    setHeroImg(aliAfter);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    // Load the image when the component mounts
    loadImage();
  }, [loadImage]);

  return (
    <>
      <Navbar />
      <StyledMainPage>
        {" "}
        {showSideAnchor && <SideAnchorLinks />}{" "}
        <StyledMainContainer>
          {" "}
          {isLoading ? (
            // Show a loading indicator while the image is loading
            <div> Loading... </div>
          ) : (
            // Once the image is loaded, display it
            <Hero img={heroImg} />
          )}{" "}
          <About />
          <Experience />
          <Projects />
          <Footer />
        </StyledMainContainer>{" "}
      </StyledMainPage>{" "}
    </>
  );
};

export default Main;
